/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /* 메인  컬러 */
  --ion-color-primary: #024ea7;
  --ion-color-primary-rgb: 2, 78, 167;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #003a7d;
  --ion-color-primary-tint: #0262d1;

  --ion-color-black: #4a4a4a;

  /** secondary **/
  /* 서브 컬러 */
  /* --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff; */

  /** grey **/
  /* 회색 */
  --ion-color-secondary: #e9ecef;
  --ion-color-secondary-rgb: 233, 236, 239;
  --ion-color-secondary-contrast: #8b959b;
  --ion-color-secondary-contrast-rgb: 139, 149, 155;
  --ion-color-secondary-shade: #bebebe;
  --ion-color-secondary-tint: #eee;

  /** White **/
  /* 흰색 */
  --ion-color-white: #fff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #fff;
  --ion-color-white-contrast-rgb: 255, 255, 255;
  --ion-color-white-shade: #fff;
  --ion-color-white-tint: #fff;



  /*****************************************************************************/
  /* cnuEr Custom style { */

    /* font color */
    --cnu-color-font-black: #4a4a4a;
    --cnu-color-font-white: #fff;
    --cnu-color-font-main: #024ea7;
    --cnu-color-font-82: #82878b;
    --cnu-color-font-77: #777;
    --cnu-color-font-66: #666;
    --cnu-color-font-99: #999;
    --cnu-color-font-navy: #7788a6;
    --cnu-color-font-skygrey: #91a2c0;
    --cnu-color-font-green: #3cb44a;
    --cnu-color-font-yellow: #ffb901;
    --cnu-color-font-orange: #ff7700;
    --cnu-color-font-red: #df3429;
    --cnu-color-font-blue: #4f8bd2;

    /* border color */
    --cnu-color-border-sky: #80a6d3;
    --cnu-color-border-skygrey: #91a2c0;
    --cnu-color-border-grey: #e1e3e8;
    --cnu-color-border-grey2: #ebedf1;

    /* background color */
    --cnu-color-bg-white: #fff;
    --cnu-color-bg-wgrey: #fafbfc;
    --cnu-color-bg-skygrey: #91a2c0;
    --cnu-color-bg-red: #dd272a;
    --cnu-color-bg-yellow: #ffb901;
    --cnu-color-bg-green: #3cb44a;

    /* iOS app 상단, 하단 safe area */
    --cnu-iossafe-area-top: constant(safe-area-inset-top); /*for iOS 11.2*/
    --cnu-iossafe-area-top: env(safe-area-inset-top); /*for iOS 11.1*/
    --cnu-iossafe-area-bottom: constant(safe-area-inset-bottom); /*for iOS 11.2*/
    --cnu-iossafe-area-bottom: env(safe-area-inset-bottm); /*for iOS 11.1*/

    /* px -> rem unit */
    --px3: 0.1875rem;
    --px5: 0.3125rem;
    --px10: 0.625rem;
    --px14: 0.875rem;
    --px15: 0.9375rem;
    --px16: 1rem;
    --px18: 1.125rem;
    --px20: 1.25rem;
    --px22: 1.375rem;
    --px24: 1.5rem;
    --px25: 1.5625rem;
    --px26: 1.625rem;
    --px28: 1.75rem;
    --px30: 1.875rem;
    --px34: 2.125rem;
    --px35: 2.1875rem;
    --px40: 2.5rem;
    --px45: 2.8125rem;
    --px50: 3.125rem;
    --px55: 3.4375rem;
    --px60: 3.75rem;
    --px65: 4.0625rem;
    --px70: 4.375rem;
    --px75: 4.6875rem;
    --px80: 5rem;
    --px85: 5.3125rem;
    --px90: 5.625rem;
    --px95: 5.9375rem;
    --px100: 6.25rem;
    --px110: 6.875rem;
    --px120: 7.5rem;
    --px130: 8.125rem;
    --px150: 9.375rem;
    --px160: 10rem;
    --px170: 10.625rem;
    --px200: 12.5rem;
    --px240: 15rem;
    --px250: 15.625rem;
    --px260: 16.25rem;
    --px270: 16.875rem;
    --px280: 17.5rem;
    --px300: 18.75rem;
    --px380: 23.75rem;
    --px400: 25rem;
    --px420: 26.25rem;
    --px450: 28.125rem;
    --px500: 31.25rem;
    --px600: 37.5rem;

    ---px15: -0.9375rem;

    /* 스타일 재정의 */
    --px16: 1.125rem;
    --px18: 1.25rem;
    --px20: 1.375rem;

  /* } cnuEr Custom style */
  /*****************************************************************************/
}






/*****************************************************************************/
/* Reset css { */

* {padding: 0; margin: 0; box-sizing: border-box; text-decoration: none;}
h1, h2, h3, h4, h5, p, a, i, b, ul, li, dl, ol {margin: 0;padding: 0;list-style: none;}
button {margin: 0;padding: 0;background: none; box-shadow: none; border: 0; outline-width: 0; text-transform: none !important;}
input[type='text'], input[type='email'], input[type='tel'], input[type='number'], input[type='password'], textarea {background: none; border: 0; outline-width: 0;}
textarea {resize: none;}
input {appearance:none; -webkit-appearance:none; -moz-appearance:none;}
input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkt-outer-spin-button {appearance:none; -webkit-appearance:none; -moz-appearance:none;}
select {appearance:none; -webkit-appearance:none; -moz-appearance:none; outline-width: 0; border: 0; border-radius: 0; background-color: transparent;}

.header-md::after, .tabs-md[tabsPlacement="top"] > .tabbar::after, .footer-md::before, .tabs-md[tabsPlacement="bottom"] > .tabbar::before {background: none;}

.button-md, .button-ios {box-shadow: none; border-radius: 2px; height: auto; background-color: transparent; margin: 0; padding: 0;}
.displaynone {display: none !important; }
.txtind {font-size: 1px; text-indent: -9999px;}

ion-button::part(native) {padding-left: 0; padding-right: 0; box-shadow: none;}

ion-menu.ios ion-list, ion-menu.md ion-list {padding: 0;}
ion-menu.md ion-list#inbox-list {border-bottom: 0;}

/* .md::part(scroll) {padding-top: 0; padding-bottom: 0;} */

ion-item::part(native) {background-color: transparent; --inner-border-width: 0 0 0 0; padding: 0; --min-height:auto;}
ion-item.ion-activated::part(native) {background-color: transparent;}

/* } Reset css 끝 */
/*****************************************************************************/





/*****************************************************************************/
/* Common css { */


body, html {font-size: 16px;}



/*input*/
input[type='text']::placeholder, input[type='email']::placeholder, input[type='tel']::placeholder, input[type='number']::placeholder, input[type='password']::placeholder, textarea::placeholder {font-size: 15px; font-size: var(--px15); font-weight: 400; letter-spacing: -0.5px; }

.nohoverbg {background:#fff !important; box-shadow: none !important;}
.nohoverbg:hover {background: #fff !important; box-shadow: none!important;}

/* 페이지 기본 스타일 */
.bg_none {background-color: transparent;}
.bg_rgba {background-color: rgba(0, 0, 0, 0.3);}
.bg_black {background-color: #000;}
.bg_fff {background-color: #fff;}
.bg_wgrey {background-color: var(--cnu-color-bg-wgrey);}
.pgwrap {width: 100%; height: auto;}
.pgwrap.has_bt {width: 100%; height: 100%;}
.cont_wrap {width: 100%; height: auto;}
.pgwrap.has_bt .cont_wrap {width: 100%; height: 100%; padding: 20px 16px; padding-bottom: 80px; display: flex; flex-direction: column;}


/* display */
.inline {display: inline-block;}
.block {display: block;}
.table {display: table;}
.tablecell {display: table-cell;}
.flex {display: flex;}
.inlineflex {display: inline-flex;}
.none {display: none;}
.visi_none {visibility: none;}

/* layout */
.align_c {text-align: center;}
.align_l {text-align: left;}
.align_r {text-align: right;}
.flex_direc_row {flex-direction: row;}
.flex_direc_col {flex-direction: column;}
.flex_align_start {align-items: flex-start;}
.flex_align_end {align-items: flex-end;}
.flex_align_center {align-items: center;}
.flex_alignself_start {align-self: flex-start;}
.flex_alignself_center {align-self: center;}
.flex_alignself_end {align-self: flex-end;}
.flex_justi_start {justify-content: flex-start;}
.flex_justi_end {justify-content: flex-end;}
.flex_justi_center {justify-content: center;}
.flex_space_around {justify-content: space-around;}
.flex_space_between {justify-content: space-between;}
.flex_space_evenly {justify-content: space-evenly;}
.flew_wrap {flex-wrap: wrap;}
.flex_glow_unset {flex-grow: unset;}
.flex_glow_1 {flex-grow: 1;}
.flex_glow_2 {flex-grow: 2;}
.flex_glow_3 {flex-grow: 3;}
.wid_fitcontent {width: fit-content;}
.ht_fitcontent {height: fit-content;}
.width_100 {width: 100%;}
.hegiht_100 {height: 100%;}
.minhegiht_100 {min-height: 100%;}
.lay_1 {width: 100%;}
.lay_2_1 {width: 50%;}
.lay_3_1 {width: 33.333333333333333%;}
.lay_3_2 {width: 66.666666666666667%;}
.lay_4_1 {width: 25%;}
.lay_5_1 {width: 20%;}
.lay_5_3 {width: 60%;}
.lay_6_1 {width: 16.666666666666667%;}
.lay_6_5 {width: 83.333333333333335%;}
.blank_box_5 {width: 5px; height: 5px; width: var(--px5); height: var(--px5);}
.blank_box_10 {width: 10px; height: 10px; width: var(--px10); height: var(--px10);}
.blank_box_15 {width: 15px; height: 15px; width: var(--px15); height: var(--px15);}
.blank_box_20 {width: 20px; height: 20px; width: var(--px20); height: var(--px20);}
.blank_box_25 {width: 25px; height: 25px; width: var(--px25); height: var(--px25);}
.blank_box_30 {width: 30px; height: 30px; width: var(--px30); height: var(--px30);}
.blank_box_35 {width: 35px; height: 35px; width: var(--px35); height: var(--px35);}
.blank_box_40 {width: 40px; height: 40px; width: var(--px40); height: var(--px40);}
.blank_box_45 {width: 45px; height: 45px; width: var(--px45); height: var(--px45);}
.blank_box_50 {width: 50px; height: 50px; width: var(--px50); height: var(--px50);}
.blank_box_60 {width: 60px; height: 60px; width: var(--px60); height: var(--px60);}



/* Font */
pre {margin: 0; padding: 0; white-space: pre-wrap;}

.txt {word-break: keep-all; font-weight: 400; letter-spacing: -0.5px; color: var(--cnu-color-font-black);}
.pre {white-space: pre-wrap;}

.lineheight_1 {line-height: 1;}
.lineheight_15 {line-height: 1.5;}
.lineheight_16 {line-height: 1.6;}

.font_size_10 {font-size: 10px; font-size: var(--px10);}
.font_size_14 {font-size: 14px; font-size: var(--px14)}
.font_size_15 {font-size: 15px; font-size: var(--px15);}
.font_size_16 {font-size: 16px; font-size: var(--px16);}
.font_size_18 {font-size: 18px; font-size: var(--px18);}
.font_size_20 {font-size: 20px; font-size: var(--px20);}
.font_size_22 {font-size: 22px; font-size: var(--px22);}
.font_size_24 {font-size: 24px; font-size: var(--px24); letter-spacing: -1px;}
.font_size_26 {font-size: 26px; font-size: var(--px26); letter-spacing: -2px;}
.font_size_28 {font-size: 28px; font-size: var(--px28); letter-spacing: -1.5px;}
.font_size_34 {font-size: 34px; font-size: var(--px34); letter-spacing: -2px;}
.font_weight_l {font-weight: 400;}
.font_weight_r {font-weight: 400;}
.font_weight_m {font-weight: 500;}
.font_weight_sb {font-weight: 600;}
.font_weight_b {font-weight: 700;}
.font_weight_eb {font-weight:800; font-weight: 900;}

/* Color */
.color_black {color: var(--cnu-color-font-black);}
.color_white {color: var(--cnu-color-font-white);}
.color_main {color: var(--cnu-color-font-main);}
.color_82 {color: var(--cnu-color-font-82);}
.color_77 {color: var(--cnu-color-font-77);}
.color_66 {color: var(--cnu-color-font-66);}
.color_99 {color: var(--cnu-color-font-99)}
.color_red {color: var(--cnu-color-font-red);}
.color_yellow {color: var(--cnu-color-font-yellow);}
.color_orange {color: var(--cnu-color-font-orange);}
.color_green {color: var(--cnu-color-font-green);}
.color_navy {color: var(--cnu-color-font-navy);}

.stat_level_1 {color: var(--cnu-color-font-red);}
.stat_level_2 {color: var(--cnu-color-font-yellow);}
.stat_level_3 {color: var(--cnu-color-font-green);}


/* Background Color */
.bg_c_black {background-color: #000;}
.bg_c_white {background-color: #fff;}
.bg_c_grey {background-color: var(--cnu-color-bg-wgrey);}
.bg_c_main {background-color: var(--cnu-color-font-main);}
.bg_c_sky {background-color: var(--cnu-color-font-navy);}
.bg_c_red {background-color: var(--cnu-color-bg-red);}
.bg_c_green {background-color: var(--cnu-color-bg-green);}
.bg_c_yellow {background-color: var(--cnu-color-bg-yellow);}
.bg_c_orange {background-color: var(--cnu-color-font-orange);}


/* overflow */
.overflow_hid {overflow: hidden;}
.overflow_y {overflow-y: auto;}
.txt_overflow_1 {overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.txt_overflow_2 { display:-webkit-box; -webkit-line-clamp: 3; -webkit-box-orient:vertical; overflow:hidden; text-overflow:ellipsis;}
.txt_overflow_3 { display:-webkit-box; -webkit-line-clamp: 4; -webkit-box-orient:vertical; overflow:hidden; text-overflow:ellipsis;}

/* i ico, img */
i.ico {display: block; background-position: center center; background-repeat: no-repeat; background-size: 100% auto; background-size: contain;}

/* button */
button {background-color: transparent;}
.btn {width: 100%; height: auto; transition: 0.4s;}
.btn_rdobox {min-height: 80px; min-height: var(--px80); border:1px solid var(--cnu-color-border-grey); border-radius: 40px; border-radius: var(--px40); text-align: center; width: 100%; display: flex; align-items: center; justify-content: center; transition: 0.3s;}
input[type='checkbox']:checked + label .btn_rdobox, input[type='radio']:checked + label .btn_rdobox {border: 1px solid var(--cnu-color-font-main); }
.hd_btn {width: 30px; height: 30px; width: var(--px30); height: var(--px30); margin: 0 15px; margin: 0 var(--px15);}
.hd_btn:first-child {margin-left: 0;}

ion-back-button {width: 30px; height: 30px; width: var(--px30); height: var(--px30); background-image: url('../assets/images/btn/btn-back.png'); background-size: 100% auto; background-size: contain; background-repeat: no-repeat; background-position: center center;}
ion-back-button::part(icon) {visibility: hidden;}

.btn.rounded_box {border-radius: 20px; border-radius: var(--px20); overflow: hidden;}
.btn.rounded_box_2 {border-radius: 10px; border-radius: var(--px10); overflow: hidden;}
.btn.rounded_box_3{border-radius: 40px; border-radius: var(--px40); overflow: hidden;}
.btn.rounded_box_3.noheight {height: auto;}
.btn.rounded_box_3.nowidth {width: auto;}
.btn.rounded_box_3.nowidth.noheight {border-radius: 30px; border-radius: var(--px30);}
.btn.rounded_box_3.nowidth.noheight::part(native) {width: auto; height: auto; padding: 15px 20px; padding: var(--px15) var(--px20); min-width: 50px; min-width: var(--px50); min-height: 20px; min-height: var(--px20); box-sizing: content-box; display:flex; align-items: center; justify-content: center;}

.btn.gray {background-color: #f1f1f1;}
.btn.selected {background-color: var(--cnu-color-font-main);color: #fff !important;}
.btn.selected .txt {color: #fff !important;}
.btn.white {background-color: #fff;}
.btn.white::part(native) {background-color: #fff;}
.btn.white.button-solid.ion-activated::part(native) {background-color: #fff !important;}

.btn_submit {height: 70px; height: var(--px70);}

/*checkbox style 1*/
.ckbox_st input[type='checkbox'] + label {display: flex; justify-content: unset;}
.ckbox_st input[type='checkbox'] + label i {width: 24px; height: 24px; background-size: 100% auto; background-position: center center; background-repeat: no-repeat; vertical-align: middle; transition: 0.3s;}
.ckbox_st input[type='checkbox'] + label .txt {padding-left: 8px;  vertical-align: middle;}

/*checkbox style 2*/
.ck_box {position: relative;}
.ck_box i.ico_btn_check {width: 26px; height: 26px; width: var(--px26); height: var(--px26);}


/* box shadow */
.boxshadow {box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);}


/* line */
.line.hori {width: 100%; height: 1px; max-height: 1px; background-color: var(--cnu-color-border-grey);}
.line.verti {width: 1px; max-width: 1px; height: 100%; }
.line.hori.short {max-width: 60px; height: 2px; }
.line.sky {background-color: var(--cnu-color-border-sky);}

.border_2px {box-shadow: 0 0 0 2px var(--cnu-color-border-grey) inset; }


/* page */
.page {min-height: 100%;}


.yellowLabel {font-size: 14px; font-size: var(--px14); font-weight: 500; padding: 2px 5px; background-color: var(--cnu-color-bg-yellow); color: #fff;border-radius: 14px; min-width: 26px; min-width: var(--px26); text-align: center;}
.yellowLabel.ml_5 {margin-left: 5px;}
.yellowLabel.position_absol {top: 0; right: -37%;}


.position_rltv {position: relative;}
.position_absol {position: absolute;}


.ht_auto {height: auto;}

/* } Common css 끝 */
/*****************************************************************************/






/*****************************************************************************/
/* Icon { */

.ico_arr_start {background-image: url('..//assets/images/btn/btn-arr-start.png');}
i.ico_btn_arr {background-image: url('../assets/images/btn/btn-arr.png'); width: 24px; width: var(--px24); height: 24px; height: var(--px24);}
i.ico_btn_arr.white {background-image: url('../assets/images/btn/btn-arr-my.png');}
i.ico_btn_phone {background-image: url('../assets/images/btn/btn-phone.png');}
i.ico_btn_filter {background-image: url('../assets/images/btn/btn-filter.png');}
i.ico_btn_ref {background-image: url('../assets/images/btn/btn-ref.png');}
i.ico_btn_more {background-image: url('../assets/images/btn/btn-more.png');}
i.ico_btn_check {background-image: url('../assets/images/btn/btn-check-off.png');}
input[type=checkbox]:checked ~ i.ico_btn_check {background-image: url('../assets/images/btn/btn-check-on.png');}
i.ico_btn_home {background-image: url('../assets/images/btn/btn-home-white.png');}
i.ico_btn_logout {background-image: url('../assets/images/btn/btn-log-out.png');}
i.ico_btn_close {background-image: url('../assets/images/btn/btn-close.png');}
i.ico_btn_arr_my {background-image: url('../assets/images/btn/btn-arr-my.png');}

/* } Icon 끝 */
/*****************************************************************************/





/*****************************************************************************/
/* Header { */

#header {width: 100%; height: auto; border-bottom: 1px solid var(--cnu-color-border-grey); position: relative;}
#header ion-toolbar {width: 100%; height: auto; padding: auto; padding: 20px 30px; padding: var(--px20) var(--px30);}
.logo img {width: auto; height: 30px; height: var(--px30); display: block;}
#header .toolbar-background {border-color: var(--cnu-color-border-grey);}

#header ion-title.md {position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

#header ion-title h1 {text-align: center;}

ion-menu-button { width: 30px; height: 30px; width: var(--px30); height: var(--px30);
  background-image: url('../assets/images/btn/btn-menu.png'); background-size: contain; background-repeat: no-repeat; background-position: center;
}
ion-menu-button::part(icon) {opacity: 0;}

/* main sub header { */
  #subHd {width: 100%; height: auto; padding: 30px; padding: var(--px30); border-radius: 0 0 30px 30px; background-color: #fff; box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);}
  #subHd .btn::part(native) {width: 100%; padding: 65px 60px; padding: var(--px65) var(--px60); border-radius: 20px; border-radius: var(--px20);}
  #subHd .btn .txt {width: 100%; height: auto;}
  #subHd .btn .txt i.ico {width: 70px; height: 70px; width: var(--px70); height: var(--px70); }
/* } main sub header */

/* } Header 끝*/
/*****************************************************************************/





/*****************************************************************************/
/* Side Menu { */

#gnb {}
ion-menu{--width: 100%; --max-width: 400px; --max-width:25rem;}
#gnb ion-list-header .txt {color: #fff;}
#gnb ion-list-header > .inner {padding: 20px 30px; padding: var(--px20) var(--px30);}
#gnb ion-list-header i.ico {width: 24px; height: 24px; width: var(--px24); height: var(--px24);}
#gnb ion-list-header i.ico.ico_btn_close {width: 30px; height: 30px; width: var(--px30); height: var(--px30);}
#gnb ion-list-header i.ico.ico_btn_arr_my {width: 16px; height: 16px; width: var(--px16); height: var(--px16);}
#gnb ion-list-header i.ico ~ .txt {padding-left: 5px;}
#gnb ion-list-header .left ion-menu-toggle {padding-right: 40px; padding-right: var(--px40);}
#gnb ion-list-header .left ion-menu-toggle:last-child {padding-right: 0;}

#gnb .listGroup {width: 100%; height: auto; padding: 20px 0; padding: var(--px20) 0;}
#gnb .listGroup .line_frame {width: 100%; height: auto; padding: 10px 30px; padding: var(--px10) var(--px30);}
#gnb .listGroup ul {width: 100%; height: auto; padding: 15px; padding: var(--px15);}
#gnb .listGroup li {width: 100%; height: auto;}
#gnb .listGroup ion-item {padding: 15px; padding: var(--px15);
font-size: 18px; font-size: var(--px18); font-weight: 400; letter-spacing: -1px; color: #4a4a4a;}

#gnb ion-item::part(detail-icon) {display: none;}

/* } Side Menu 끝 */
/*****************************************************************************/






/*****************************************************************************/
/* Login { */

#page_login {background-color: var(--cnu-color-bg-wgrey);}
#page_login .contWrap {padding: 60px; padding: var(--px60);}
#page_login .contWrap > .inner {width: 100%; max-width: 500px; max-width: var(--px500);}
#page_login .inputGroups .input_frame {padding: 10px 0; padding: var(--px10) 0;}
#page_login .btnGroupsWrap {padding: 0;}
#page_login .btnGroupsWrap.submitStyle .btn_frame {padding: 0;}
#page_login .logo {padding-bottom: 80px; padding-bottom: var(--px80);}
#page_login .logo img {display: block; width: auto; height: 200px; height: 12.5rem; margin: 0 auto;}
#page_login .btn_box {padding: 20px 10px; padding: var(--px20) var(--px10);}
#page_login .btnGroupsWrap {padding: 20px 0; padding: var(--px20) 0;}
#page_login .co_logos {width: 100%; height: auto; padding-top: 40px; padding-top: var(--px40);}
#page_login .co_logos > .inner {}
#page_login .co_logos .img_frame {width: 30%; min-width: 150px; min-width: var(--px150); max-width: 200px; max-width: var(--px200);}

/* } Login 끝 */
/*****************************************************************************/







/*****************************************************************************/
/* 메인 페이지 { */

#mainPage {background-color: #fafbfc;}

#mainPage .cont_wrap {width: 100%; height: auto; padding: 15px; padding: var(--px15);}

#mainPage .txt_frame.tit {padding: 15px; padding: var(--px15);}

.mainBtnsWrap {width: 100%; height: auto;}
.mainBtnsWrap ul {width: 100%; height: auto;}
.mainBtnsWrap ul .btn_frame {padding: 15px; padding: var(--px15);}
.mainBtnsWrap ul .btn_frame .btn {display: flex; flex-direction: row; align-items: center; justify-content: space-between; padding: 40px; padding: var(--px40);}
.mainBtnsWrap ul .btn_frame .txt {display: flex; flex-grow: 1; height: auto; flex-direction: row; align-items: center; text-align: left; line-height: 1.3;}
.mainBtnsWrap ul .btn_frame .img_frame {width: 80px; height: 65px; width: var(--px80); height: var(--px65); }
.mainBtnsWrap ul .btn_frame img {width: 100%; height: 100%; object-fit: contain; padding-right: 5px; padding-right: var(--px5);}
.mainBtnsWrap ul .btn_frame i.ico_btn_arr {width: 24px; height: 24px; width: var(--px24); height: var(--px24);}

/* } 메인 페이지 끝 */
/*****************************************************************************/





/*****************************************************************************/
/* Footer { */

.footer {width: fit-content;}
.footer .ft_inner {width: fit-content;}
.footer .btn_frame {padding: 0 30px; padding: 0 var(--px30); padding-bottom: 15px; padding-bottom: var(--px15);}

.btn.ft_btn {width: 170px; width: var(--px170); height: 60px; height: var(--px60); border-radius: 30px; border-radius: var(--px30); box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3); overflow: hidden; position: relative;}
.btn.ft_btn .txt {padding-right: 50px; padding-right: var(--px50);}
.btn.ft_btn .ico_frame {width: 60px; height: 60px; width: var(--px60); height: var(--px60); border-radius: 50%; background-color: var(--cnu-color-font-main); position: absolute; top: 0; right: 0; z-index: 1;}
.btn.ft_btn .ico_frame i.ico {width: 100%; height: 100%;}

/* } Footer */
/*****************************************************************************/






/*****************************************************************************/
/* 서브페이지 공통 건텐츠 스타일 { */
  
  /* 컨텐츠 타이틀 */
  .ContTit {width: 100%; height: auto; padding: 20px 30px 0; padding: var(--px20) var(--px30) 0;}


  /* 버튼 그룹 { */
  .btnGroupsWrap {width: 100%; height: auto; padding: 15px; padding: var(--px15);}

  .btnGroupsWrap .groupTit {width: 100%; height: auto; padding: 15px; padding: var(--px15);}
  
    /* 스타일 - 리스트 타입 */
    .btnGroupsWrap.listStyle {}
    .btnGroupsWrap.listStyle ul {}
    .btnGroupsWrap.listStyle ul .btn_frame {padding: 15px; padding: var(--px15);}
    .btnGroupsWrap.listStyle ul .btn_frame .btn {padding: 30px 40px; padding: var(--px30) var(--px40);}
    .btnGroupsWrap.listStyle ul .btn_frame.checked .btn {background: #f1f1f1;}
    .btnGroupsWrap.listStyle ul .btn_frame.checked .btn * {background: #f1f1f1;border: 0;flex-glow: 0}

    /* 스타일 - 리스트 타입 */
    .inputhide {display: block; width: 0; height: 0; overflow: hidden; opacity: 0; position: absolute; top: -1px; left: -1px; }
    .btnGroupsWrap.ckboxStyle {}
    .btnGroupsWrap.ckboxStyle ul {}
    .btnGroupsWrap.ckboxStyle .btn_frame {padding: 10px; padding: var(--px10);}
    .btnGroupsWrap.ckboxStyle .ck_box {display: block; width: 100%; height: auto; position: relative; }
    .btnGroupsWrap.ckboxStyle .ck_box .txt_frame {width: 100%; height: auto; padding: 25px; padding: var(--px25); box-shadow: 0 0 0 2px var(--cnu-color-border-grey) inset; border-radius: 40px; border-radius: var(--px40);}
    .btnGroupsWrap.ckboxStyle .ck_box .txt_frame, .btnGroupsWrap.ckboxStyle .ck_box .txt_frame .txt {transition: 0.2s;}
    .btnGroupsWrap.ckboxStyle .ck_box .inputhide:checked ~ .txt_frame {box-shadow: 0 0 0 2px var(--cnu-color-border-sky) inset;}
    .btnGroupsWrap.ckboxStyle .ck_box .inputhide:checked ~ .txt_frame .txt {color: var(--cnu-color-font-main);}
    .btnGroupsWrap.ckboxStyle.allCk .ck_box .txt_frame {box-shadow: 0 0 0 2px var(--cnu-color-border-sky) inset;}
  /* } 버튼 그룹 끝 */

  /* 입력(input, textarea) 그룹 { */
  .inputGroups {}
  .inputGroups .input_box {}
  .inputGroups .input_frame {width: 100%; height: auto; padding: 15px; padding: var(--px15);}
  input.basic {display: block; width: 100%; height: 80px; height: var(--px80); border-radius: 40px; border-radius: var(--px40); box-shadow: 0 0 0 2px var(--cnu-color-border-grey); padding: 0 40px; padding: 0 var(--px40); background-color: #fff;}
  ion-input.basic {display: block; width: 100%; border-radius: 40px; box-shadow: 0 0 0 2px var(--cnu-color-border-grey); background-color: #fff;}
  ion-input.basic input.native-input {padding:0; height: 80px; height: var(--px80); font-size: 20px; font-size: var(--px20); font-weight:400; color:var(--cnu-color-font-black); padding-top: 0; padding-bottom: 0; padding-left: 0; padding-right: 0; padding: 0 40px; padding: 0 var(--px40);}
  ion-input.basic input.native-input::placeholder {font-size: 20px; font-size: var(--px20); font-weight: 400; color: var(--cnu-color-font-82);}
  input.basic.shadow {box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);}
  ion-input.basic.shadow {box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);}
  ion-input.basic.shadow input.native-input::placeholder {color: #82878b;}
  /* } 입력(input, textarea) 그룹 끝 */

  .btnGroupsWrap.submitStyle .btn_frame {padding: 0 15px; padding: 0 var(--px15);}


  /* 표 { */
  .table_wrap {width: 100%; height: auto;}
  .tbl_layout {table-layout: fixed;}
  .basic_tbl {width: 100%; height: auto; border-collapse: 0; border-top: 2px solid var(--cnu-color-border-grey2); border-bottom: 2px solid var(--cnu-color-border-grey2);}
  .basic_tbl.size_normal th, .basic_tbl.size_normal td, .basic_tbl.size_normal .txt {font-size: 16px; font-size: 1.125rem;}
  .basic_tbl.align_c_all th, .basic_tbl.align_c_all td {text-align: center;}
  .basic_tbl .thtxt, .basic_tbl .thtxt .txt {color: var(--cnu-color-font-navy); font-weight: 500;}
  .basic_tbl th, .basic_tbl td {padding: 15px 5px; padding: var(--px15) var(--px5); border-bottom: 1px solid var(--cnu-color-border-grey2);}
  .basic_tbl tr:last-child td {border-bottom: 0;}
  /* } 표 */


  /* 게시판 목록 (공지사항) { */
  .notiLabel {color: var(--cnu-color-font-main); border:1.6px solid var(--cnu-color-font-main); border-radius: 20px; padding: 3px 10px; margin-right: 10px;}
  .list_item .new {display: inline-flex; text-align: center; align-items: center; justify-content: center; margin-left: 6px; margin-left: var(--px5); width: 22px; height: 22px; font-size: 14px; font-size: var(--px14); font-weight: 500; color: #fff; border-radius: 50%; background-color: var(--cnu-color-bg-yellow);}
  .boardListWrap {width: 100%; height: auto; padding: 30px; padding: var(--px30);}
  .boardListWrap .listGroup {border-bottom: 2px solid var(--cnu-color-border-grey2);}
  .boardListWrap .listGroup:first-child {border-top: 2px solid var(--cnu-color-border-grey2);}
  .boardListWrap .list_item {width: 100%; height: auto; border-bottom: 1px solid var(--cnu-color-border-grey2);}
  .boardListWrap .list_item .lstCon {padding: 20px 0; padding: var(--px20) 0;}
  .boardListWrap .list_item .lstCon.tit {width: 100%; height: auto; display: flex; flex-direction: row; align-items: center; justify-content: space-between;}
  .boardListWrap .list_item .lstCon.tit .txt_frame {width: 100%; height: auto; display: flex; flex-direction: column; align-items: flex-start; justify-content: space-between; padding-right: 30px; padding-right: var(--px30); padding-left: 10px; padding-left: var(--px10);}
  .boardListWrap .listGroup.noti .list_item .lstCon.tit .txt_frame {flex-direction: row; align-items: center; justify-content: space-between; }
  .boardListWrap .list_item .lstCon.tit .txt {line-height: 1.5;}
  .boardListWrap .list_item .lstCon.tit .txt.ldate {padding-bottom: 6px;}
  .boardListWrap .list_item .lstCon.tit .ico_frame {width: 24px; height: 24px; width: var(--px24); height: var(--px24);}
  .boardListWrap .list_item .lstCon.tit i.ico {width: 100%; height: 100%; transition: 0.3s;}
  .boardListWrap .list_item .lstCon.con {padding: 0;}
  .boardListWrap .list_item .lstCon.con > .inner {width: 100%; height: auto; padding: 10px; padding: var(--px10); background-color: var(--cnu-color-bg-wgrey);}
  .boardListWrap .list_item .lstCon.con .img_frame, .boardListWrap .list_item .lstCon.con .txt_frame {width: 100%; height: auto; padding: 10px; padding: var(--px10);}
  .boardListWrap .list_item .lstCon.con .img_frame {width: 100%; max-height: 700px; max-height: 43.75rem; max-height: 68vh; overflow: hidden;}
  .boardListWrap .list_item .lstCon.con .img_frame img {display: block; width: 100%; height: 100%; object-fit: contain;}

  .boardListWrap .list_item .lstCon.con {max-height: 0; overflow: hidden; transition: 0.5s;}
  .boardListWrap .list_item.act .lstCon.con {max-height: 9999px;}
  .boardListWrap .list_item.act .lstCon.tit i.ico {transform: rotate(180deg);}
  /* } 게시판 목록 */
  
/* } 서브페이지 공통 건텐츠 스타일  끝 */
/*****************************************************************************/






/*****************************************************************************/
/* 병원정보 { */

/* 병원정보 박스 { */
  .itemGroupsWrap {width: 100%; height: auto; padding: 15px; padding: var(--px15);}
  .itemGroupsWrap ul .box_frame {padding: 15px; padding: var(--px15);}

  .itemGroupsWrap ul .box_frame .white_box {border-radius: 20px; border-radius: var(--px20);}
  .itemGroupsWrap ul .box_frame .white_box .boxtit {padding: 20px 30px; padding: var(--px20) var(--px30); border-bottom: 3px solid var(--cnu-color-border-grey2);}
  .itemGroupsWrap ul .box_frame .white_box .boxtit.simple_info {padding: 25px; padding: var(--px25); border-bottom: 0;}
  .itemGroupsWrap ul .box_frame .white_box .boxtit.simple_info .ico {width: 30px; height: 30px; width: var(--px30); height: var(--px30); transition: 0.2s;}
  .itemGroupsWrap .btn.btn_ico {margin-bottom: var(---px15);}

  .itemGroupsWrap ul .box_frame .white_box .boxcon { }
  .itemGroupsWrap ul .box_frame .white_box .boxcon_item {padding-bottom: 30px; padding-bottom: var(--px30);}
  .itemGroupsWrap ul .box_frame .white_box .boxcon_item:last-child {padding-bottom: 0;}

  .itemGroupsWrap ul .box_frame .white_box .boxcon {padding: 0; max-height: 0; overflow: hidden; }
  .itemGroupsWrap ul .box_frame .white_box.open .boxcon {max-height: 999px; padding: 30px; padding: var(--px30); padding-top: 0;}
  .itemGroupsWrap ul .box_frame .white_box.open .boxtit.simple_info .ico {transform: rotate(-180deg);} 
  
/* } 병원정보 박스 */


/* 전화 버튼 */
.btn_call {padding: 15px 20px; padding: var(--px15) var(--px20); border:1px solid #d0d3d9; border-radius: 30px;}
.btn_call i.ico {width: 20px; width: var(--px20); height: 20px; height: var(--px20); margin-right: var(--px5);}

/* Level */
.label_level {padding: 5px 10px; padding: var(--px5) var(--px10); background-color: #dbdbdb; border-radius: 20px; min-width: 80px; min-width: var(--px80); text-align: center; height: fit-content;letter-spacing: -1px;}
.label_level.level_1 {background-color: var(--cnu-color-bg-red);}
.label_level.level_2 {background-color: var(--cnu-color-bg-yellow);}
.label_level.level_3 {background-color: var(--cnu-color-bg-green);}

/* info label */
.infolabel {
  width: calc((100% - (var(--px5) * 8)) / 4);
  min-width: 120px; min-width: var(--px120); 
  height: 42px; height: 2.625rem; 
  padding: 0 20px; padding: 0 var(--px20); 
  border: 1px solid var(--cnu-color-border-skygrey); 
  background-color: #fff; 
  border-radius: 30px;
  display: flex; flex-direction: row; 
  align-items: center; justify-content: space-between; 
  margin: 5px; margin: var(--px5);
  box-sizing: border-box;
  white-space: nowrap;
}
.infolabel .txt {color: var(--cnu-color-font-skygrey);}
.infolabel.red {border-color: var(--cnu-color-bg-red);}
.infolabel.red .txt {color: var(--cnu-color-bg-red);}
.infolabel.yellow {border-color: var(--cnu-color-bg-yellow);}
.infolabel.yellow .txt {color: var(--cnu-color-bg-yellow);}
.infolabel.green {border-color: var(--cnu-color-bg-green);}
.infolabel.green .txt {color: var(--cnu-color-bg-green);}
.infolabel.navy {border-color: var(--cnu-color-font-blue);}
.infolabel.navy .txt {color: var(--cnu-color-font-blue);}
.infolabel.grey {border-color: var(--cnu-color-font-navy);}
.infolabel.grey .txt {color: var(--cnu-color-font-navy);}

.greylinebox {border: 2px solid var(--cnu-color-border-grey2); border-radius: 10px; border-radius: var(--px10);}
.greylinebox > .inner {padding: 15px; padding: var(--px15);}
.greylinebox .left {display: flex; flex-grow: 1; max-width: 29.5%; padding-right: 60px; padding-right: var(--px60);}
.greylinebox .right {display: flex; flex-grow: 2; max-width: 69.5%;}

/* } 병원정보  끝 */
/*****************************************************************************/






/*****************************************************************************/
/* 레이어팝업 { */

ion-modal {width: 100%; height: auto;}
.modal-wrapper.sc-ion-modal-ios, .modal-shadow.sc-ion-modal-ios {--border-radius: 20px; --border-radius: var(--px20);}
ion-modal#LayerpopFilter .modal-wrapper.sc-ion-modal-ios, ion-modal#LayerpopFilter .modal-shadow.sc-ion-modal-ios {width: 80%; max-width: 500px; max-width: var(--px500); min-height: 400px; height: auto; min-height: var(--px400);}
ion-modal#layerpopStartClassifi .modal-wrapper.sc-ion-modal-ios, ion-modal#layerpopStartClassifi .modal-shadow.sc-ion-modal-ios {width: 80%; max-width: 500px; max-width: var(--px500); min-height: 300px; height: 28rem; min-height: var(--px380);}
ion-modal#LayerpopLevel .modal-wrapper.sc-ion-modal-ios, ion-modal#LayerpopLevel .modal-shadow.sc-ion-modal-ios {width: 80%; max-width: 500px; max-width: var(--px500); min-height: 280px; height: auto; min-height: var(--px280);}
ion-modal#LayerpopAlert .modal-wrapper.sc-ion-modal-ios, ion-modal#LayerpopAlert .modal-shadow.sc-ion-modal-ios {width: 80%; max-width: 500px; max-width: var(--px500); min-height: 280px; height: auto; min-height: var(--px280);}

ion-modal#layerpopNonClassifi .modal-wrapper.sc-ion-modal-ios, ion-modal#layerpopNonClassifi .modal-shadow.sc-ion-modal-ios {width: 80%; max-width: 500px; max-width: var(--px500); min-height: 420px; height: auto; min-height: var(--px420);}

ion-modal#LayerpopParameter .modal-wrapper.sc-ion-modal-ios, ion-modal#LayerpopParameter .modal-shadow.sc-ion-modal-ios {width: 80%; max-width: 500px; max-width: var(--px500); min-height: 450px; height: auto; min-height: var(--px450);}
ion-modal#LayerpopParameterS .modal-wrapper.sc-ion-modal-ios, ion-modal#LayerpopParameterS .modal-shadow.sc-ion-modal-ios {width: 80%; max-width: 500px; max-width: var(--px500); min-height: 450px; height: auto; min-height: var(--px450);}
ion-modal ion-header {width: 100%; height: auto;}
ion-modal ion-header > .inner {width: 100%; height: auto; padding: 30px; padding: var(--px30);}
ion-modal ion-header > .inner.pdbottom_0 {padding-bottom: 0;}
ion-modal ion-header .txt {padding-bottom: 8px; padding-bottom: var(--px10);}
ion-modal ion-header .txt:last-child {padding-bottom: 0;}

ion-modal ion-content {width: 100%;}
ion-modal ion-content > .inner {width: 100%;}

ion-modal ion-footer > .inner {width: 100%; height: auto; padding: 20px 0; padding: var(--px20) 0;}

#layerpopStartClassifi ion-content > .inner {padding: 20px; padding: var(--px20);}
#layerpopStartClassifi ion-content .btn_frame:not(.lay_2_1) {width: 100%; height: auto; padding: 10px; padding: var(--px10);}
#layerpopStartClassifi ion-content .btn::part(native) {padding: 42px 50px; padding: var(--px40) var(--px50);}
#layerpopStartClassifi ion-content .btn_txt .btn::part(native) {padding: 0;}

#layerpopNonClassifi ion-content::part(scroll) { box-sizing: content-box; min-height: 100%; padding: 30px; padding: var(--px30); padding-top: 10px; padding-top: var(--px10);}

.btn_txt {width: 100%; display: flex; flex-direction: row; justify-content: flex-end; align-items: center;}
.btn_txt ion-button {width: 100px; width: var(--px100); height: 50px; height: var(--px50);}

.pdAll_0 {padding: 0 !important;}
.pdAll_10 {padding: 10px; padding:var(--px10); }
.pdAll_20 {padding: 20px; padding:var(--px20); }
.pdAll_15 {padding: 15px; padding:var(--px15); }
.pdTop_0 {padding-top: 0;}

table.hospitalManageList {width: 100%;margin: 0 auto;padding: 0;border-collapse: collapse;}
table.hospitalManageList thead th {padding: var(--px10);border-top: solid 1px #82878b;}
table.hospitalManageList tbody td {padding: var(--px10);border-top: solid 1px #82878b;border-bottom: solid 1px #82878b;}


table.noticeWrite {width: 100%;margin: 0 auto;padding: 0;border-collapse: collapse;}
table.noticeWrite thead th {}
table.noticeWrite tbody td {}

.hmlInput, .hmlTextarea {border: solid 1px #d1d1d1;}

/* 
ion-modal {width: 100%; height: 100%; position: relative; background-color: transparent;}
ion-modal .modal-wrapper.sc-ion-modal-ios, ion-modal .modal-shadow.sc-ion-modal-ios {width: 100%; height: 100%; max-width: none; max-height: none; background-color: transparent;}
html.ios ion-modal .ion-page {width: 100%; height: 100%; max-height: none;}
ion-modal .backDropBg {width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.4); position: absolute; top: 0; left: 0; z-index: 999;}
ion-modal .layerWrap {width: 100%; height: 100%; position: absolute; top: 0; left: 0; z-index: 1000; display: flex; justify-content: center; align-items: center;}
ion-modal .layerBox {width: 100%; height: auto; max-width: 70%; min-width: 500px; background-color: #fff;} */

/* } 레이어팝업 끝 */
/*****************************************************************************/














@media screen and (max-width: 720px) {

  body, html {font-size: 14px;}

  #header ion-toolbar {padding-top: 0.5rem; padding-bottom: 0.5rem;}

}



@media screen and (max-width: 600px) {

  body, html {font-size: 13px;}

}

@media screen and (max-width: 512px) {

  body, html {font-size: 10px;}

}

@media screen and (max-width: 420px) {
  /* body, html {font-size: 10px;} */
  #header ion-toolbar {padding-top: 0.2rem; padding-bottom: 0.2rem;}
}

@media screen and (max-width: 380px) {
  .xs_hide {display: none;}
  .btn_call i.ico {margin: 0;}
}

@media screen and (max-width: 359px) {
  .infolabel {width: calc((100% - var(--px5) * 8) / 2);}
}


@media (orientation: landscape) {

  @media screen and (min-width: 800px) {
    .itemGroupsWrap li.lay_1 {width: 50%;}
    .itemGroupsWrap .btn_call {display: none;}
    .itemGroupsWrap .btn_submit {display: none;}
    .infolabel {width: calc((100% - var(--px5) * 8) / 2);}
    
  }

  @media screen and (min-width: 1200px) {
    .itemGroupsWrap li.lay_1 {width: 33.33%;}
  }  
  

  .greylinebox .left, .greylinebox .right {max-width: none; width: 100%;}

}

.small_btn {border: solid 1px #d1d1d1;padding: 5px;margin-left: 10px;color: #000;}

/* 병원정보 하단 버튼 */
.hi-extra-btn {margin-top: 1.5rem;width: 100%;height: 6rem;background-color: #fff;border-radius: 1rem;border: solid 2px #024ea7;color: #024ea7;font-weight: bold;font-size: 1.5em;}
.hi-extra-btn img {margin-bottom: -4px;width: 1.4em;height: 1.4em;}